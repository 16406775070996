import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'gatsby';
import styled from 'styled-components';

import { OnlineHeaderStep } from 'shared/OnlineHeaderStep';

import { Section as SectonOrg } from '../../shared/Blocks';
import MainLayout from '../../shared/MainLayout';
import DolApps from '../../shared/Marketing/Online/DolApps';
import {
  FixedBody,
  FixedFooter,
  Main
} from '../../shared/Marketing/Online/shared';
import MySEO from '../../shared/MySEO';
import HeaderSchedulePage from '../../shared/Header/HeaderSchedulePage';

const Section = styled(SectonOrg)`
  background-color: #fbfbfb;

  @media screen and (min-width: 1024px) {
    min-height: 700px;
    display: flex;
    justify-content: stretch;
  }
`;

class IndexPage extends React.PureComponent {
  render() {
    return (
      <MainLayout
        showFooterRegister={false}
        noMenu
        noFooter
        hideSideCTA
        noHeader
        renderCustomHeader={() => (
          <HeaderSchedulePage hideMobileBottomNav noMenu />
        )}
      >
        <MySEO title="Bước 5: Khóa học online, trải nghiệm như offline" />
        <Main>
          <OnlineHeaderStep activeStep={4} />
          <FixedBody>
            <div className="RespHeader Step5">
              <div className="cirle">
                <div className="label">
                  <b>5</b>/5
                </div>
                <div className="circleProgress">
                  <div className="circleCommon Left ">
                    <span
                      className="progressPercent"
                      style={{ transform: 'rotate(180deg)' }}
                    />
                  </div>
                  <div className="circleCommon Right ">
                    <span className="progressPercent" />
                  </div>
                </div>
              </div>
              <h6 style={{ paddingTop: '7px' }}>Luyện tập</h6>
            </div>
            <Section selfish>
              <DolApps />
            </Section>
          </FixedBody>

          <FixedFooter>
            <div className="wrapper-footer">
              <Link to="/online/step4" className="btn btnBack">
                Quay lại
              </Link>
              <a href="/online/step6" target="_blank" className="btn btnNext">
                Nhận học bổng
              </a>
            </div>
          </FixedFooter>
        </Main>
      </MainLayout>
    );
  }
}

IndexPage.propTypes = {
  pageContext: PropTypes.shape({
    meta: PropTypes.string.isRequired
  }).isRequired
};

export default IndexPage;
