import React from 'react';
import styled, { keyframes } from 'styled-components';
import cl from 'classnames';

import { InView } from 'react-intersection-observer';
import GlyphInterfaceIcDataDownload from '@dolstaff/shared/es/icons/GlyphInterfaceIcDataDownload';
import Image from '../../../Image';
import getAssetLink from '../../../../utils/getAssetLink';
import { Container } from '../../../Blocks';
import MyButton from '../../../MyButton';
import Title, { H2 } from '../../../MainLayout/Title';

import { appData } from './consts';

const statusBarMoving = keyframes`
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
`;
/* ----------------------------------------------
 * Generated by Animista on 2019-11-28 10:13:39
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
const swingInTopFwd = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
/* ----------------------------------------------
 * Generated by Animista on 2019-12-2 10:51:5
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation ping
 * ----------------------------------------
 */
const ping = keyframes`
  0% {
    transform: scale(0.2);
    opacity: 0.8;
  }
  80% {
    transform: scale(1.2);
    opacity: 0;
  }
  100% {
    transform: scale(2.2);
    opacity: 0;
  }
`;

const Root = styled.div`
  width: 100%;
`;

const Main = styled.div`
  .mainLeft {
    margin-bottom: 2em;
  }

  .mainRight {
    min-width: 200px;
  }

  @media screen and (min-width: 1024px) {
    display: flex;
    align-items: center;

    .mainLeft {
      width: 55%;
      margin-right: 2em;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .mainRight {
      width: 45%;
      margin-left: 2em;
      margin-top: 0;
    }
  }
`;

const AppItemWrp = styled.div`
  margin-bottom: 2em;
  max-width: 320px;

  .statusBar {
    width: 100%;
    height: 2px;
    background-color: #dfe1e6;
    position: relative;
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      height: 2px;
      background-color: #d14242;
      content: '';
    }
  }
  .statusBar.moving {
    &:after {
      animation: ${statusBarMoving} 30s infinite;
    }
  }

  &.active {
    .statusBar:after {
      animation: unset;
      width: 100%;
    }
  }
`;

const AppItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  min-height: 48px;
  cursor: pointer;
  user-select: none;
  transition: all 0.3s;
  margin-bottom: 1em;

  .title {
    font-size: 18px;
    color: #2d3742;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 8px;
  }
  .subtitle {
    font-size: 14px;
    color: #7a869a;
  }

  &:hover {
    border-bottom-color: #2d3742;
  }
  &.active {
  }
`;

const AppList = styled.div`
  min-width: 300px;
  display: none;

  @media screen and (min-width: 800px) {
    display: block;
  }
  @media screen and (min-width: 1024px) {
    margin-top: 3em;
  }
`;

const AppOnboardingWrp = styled.div`
  margin-bottom: 3em;
  overflow: hidden;
  max-width: 100vw;

  .mobileContent {
    margin-bottom: 2em;

    .title {
      font-size: 18px;
      color: #2d3742;
      font-weight: 500;
      line-height: 1.2;
      margin-bottom: 8px;
    }
    .subtitle {
      font-size: 14px;
      color: #7a869a;
    }
  }

  @media screen and (min-width: 800px) {
    margin-bottom: 0;

    .mobileContent {
      display: none;
    }
  }
`;
const AppOnboarding = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 250px;

  .onboardingItem {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
  }
  .onboardingItemMobile {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
  }

  video {
    display: block;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
  }

  .onboardingItemDot {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: #d14242;
    color: white;
    line-height: 28px;
    text-align: center;
    position: relative;
    user-select: none;

    &:after {
      z-index: -1;
      content: '';
      display: block;
      width: 42px;
      height: 42px;
      border-radius: 100%;
      position: absolute;
      left: -6px;
      top: -6px;
      background-color: #d14242;
      animation: ${ping} 1.2s ease-in-out infinite both;
    }
  }

  @media screen and (min-width: 800px) {
    display: none;
    max-width: 300px;

    &.swingInTopFwd {
      display: block;
      animation: ${swingInTopFwd} 0.8s;
    }
  }
`;

const VideoWrp = styled.div`
  width: 700px;
  max-width: 100vw;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  transform: translate(-50%, -50%);
`;

const art01 = {
  handle: 'CgotzZKmQWSuFdn7udBt',
  width: '2470',
  height: '2470'
};

const video = {
  appLMS: { handle: 'U0u1nwe2TpaEfcypgc9k' },
  appOnlineTest: { handle: 'djgPm8SHQ0uorledhQoV' },
  appVocab: { handle: 'djgPm8SHQ0uorledhQoV' }
};

const APP_LIST = ['appLMS', 'appOnlineTest', 'appVocab'];

class DolRegister extends React.PureComponent {
  constructor(props) {
    super(props);

    this.appRef = React.createRef();

    this.show = {
      appLMS: true,
      appOnlineTest: false,
      appVocab: false
    };

    this.state = {
      activeApp: APP_LIST[0],
      activeAppIndex: 0,
      isUserIntereacted: false
    };
  }

  componentWillUnmount() {
    clearInterval(this.indexIntroInterval);
    clearTimeout(this.triggerDotTimeout);
  }

  playListVideo = () => {
    const { activeAppIndex: index } = this.state;

    if (window.innerWidth < 800) {
      APP_LIST.forEach(app => {
        this.playVideo(app);
      });
    } else {
      this.playVideo(APP_LIST[index]);
    }

    this.indexIntroInterval = setInterval(() => {
      const { activeAppIndex } = this.state;
      let nextActiveAppIndex = activeAppIndex + 1;
      if (nextActiveAppIndex > APP_LIST.length - 1) {
        nextActiveAppIndex = 0;
      }

      const activeApp = APP_LIST[nextActiveAppIndex];
      this.setState({
        activeAppIndex: nextActiveAppIndex,
        activeApp
      });

      this.playVideo(activeApp);
    }, 30000);
  };

  stopListVideo = () => {
    APP_LIST.forEach(i => this.stopVideo(i));
  };

  playVideo = videoName => {
    if (this.appRef && this.appRef.current) {
      const [item] = this.appRef.current.querySelectorAll(
        `#video-${videoName}`
      );
      if (item) {
        item.currentTime = 0;
        item.play();
      }
    }
  };

  stopVideo = videoName => {
    if (this.appRef && this.appRef.current) {
      const [item] = this.appRef.current.querySelectorAll(
        `#video-${videoName}`
      );
      if (item) {
        item.pause();
      }
    }
  };

  setActiveApp = value => {
    this.setState({
      activeApp: value,
      activeAppIndex: -1,
      isUserIntereacted: true
    });

    this.playVideo(value);
    clearInterval(this.indexIntroInterval);
  };

  render() {
    const { activeApp, activeAppIndex, isUserIntereacted } = this.state;

    return (
      <InView
        style={{ width: '100%' }}
        onChange={inView => {
          if (inView) {
            this.playListVideo();
          } else {
            this.stopListVideo();
          }
        }}
      >
        <Root ref={this.appRef}>
          <Container>
            <Main>
              <div className="mainLeft">
                <Title
                  className="small"
                  style={{ marginBottom: '1em', fontWeight: 'bold' }}
                >
                  BƯỚC 05: LUYỆN TẬP CÙNG&nbsp;APP
                </Title>
                <H2 className="small">
                  Hệ thống luyện tập online <b>tích hợp trí tuệ nhân tạo</b> của
                  DOL
                </H2>

                <AppList>
                  {APP_LIST.map((i, index) => (
                    <AppItemWrp
                      key={i.id}
                      className={cl(i, {
                        active: isUserIntereacted && activeApp === i
                      })}
                    >
                      <AppItem
                        role="button"
                        className={cl(i, { active: activeApp === i })}
                        onClick={() => {
                          this.setActiveApp(i);
                        }}
                      >
                        <div className="body">
                          <div className="title">
                            {appData[i].info.title1}{' '}
                            <span className="em">{appData[i].info.title2}</span>
                          </div>
                          <div className="subtitle">
                            {appData[i].info.subtitle}
                          </div>
                        </div>
                      </AppItem>
                      <div
                        className={cl('statusBar', {
                          moving: activeAppIndex === index
                        })}
                      />
                    </AppItemWrp>
                  ))}

                  <MyButton size="large" type="rdd" disabled>
                    For DOL&apos;s students only
                    <span style={{ marginLeft: '5em' }}>
                      <GlyphInterfaceIcDataDownload size={24} />
                    </span>
                  </MyButton>
                </AppList>
              </div>

              <div className="mainRight" style={{ position: 'relative' }}>
                {APP_LIST.map(appName => (
                  <AppOnboardingWrp key={appName}>
                    <VideoWrp>
                      <Image
                        image={art01}
                        maxWidth={700}
                        backgroundColor="transparent"
                      />
                    </VideoWrp>
                    <div className="mobileContent">
                      <div className="title">
                        {appData[appName].info.title1}{' '}
                        <span className="em">
                          {appData[appName].info.title2}
                        </span>
                      </div>
                      <div className="subtitle">
                        {appData[appName].info.subtitle}
                      </div>
                    </div>
                    <AppOnboarding
                      className={cl({ swingInTopFwd: activeApp === appName })}
                    >
                      <video
                        className="videoPlayer"
                        autoPlay
                        loop
                        muted
                        playsInline
                        id={`video-${appName}`}
                        src={getAssetLink({ item: video[appName] })}
                        type="video/mp4"
                        style={{ width: '264px', height: '570px' }}
                        onClick={e => {
                          e.target.play();
                        }}
                      >
                        <track kind="captions" />
                      </video>
                    </AppOnboarding>
                  </AppOnboardingWrp>
                ))}
              </div>
            </Main>
          </Container>
        </Root>
      </InView>
    );
  }
}

DolRegister.propTypes = {};

export default DolRegister;
