import styled from 'styled-components';

export const Panel = styled.div`
  height: 100%;

  .panelImage {
    position: relative;
  }
  .panelContent {
    margin-bottom: 2em;
  }
  .bannerWrp {
    max-width: 550px;
    margin-left: auto;
    position: relative;
    z-index: 1;
    margin-top: 0;
    margin-bottom: 0;
  }

  @media screen and (min-width: 1024px) {
    display: flex;
    flex-direction: row;
    align-items: center;

    .panelImage {
      flex: auto;
    }
    .panelContent {
      flex: 520px 0 0;
      margin-bottom: 4em;
    }
    .bannerWrp {
    }
  }
`;

export const FixedBody = styled.div`
  background-color: #fbfbfb;
  padding-bottom: 8em;

  @media screen and (min-width: 1024px) {
    padding-bottom: 0;
    height: calc(100vh - 152px);
    overflow-y: auto;
  }
`;

export const Main = styled.main`
  .vh100 {
    height: calc(100vh - 152px);
    padding-top: 0;
    overflow-y: auto;
    b {
      color: #d14242;
    }
  }
  .vh100.noFooter {
    height: calc(100vh - 72px);
    overflow: hidden;
  }
  h3.small {
    font-weight: bold !important;
  }
  .headerStep {
    position: fixed;
    top: 0;
    height: 72px;
    max-width: 1140px;
    width: calc(100% - 40px);
    padding-left: 212px;
    z-index: 100;
    left: 50%;
    transform: translateX(-50%);
    align-items: center;
    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  @media screen and (min-width: 1024px) {
    .headerStep {
      display: flex;
    }
  }

  .headerStep ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  .headerStep ul li {
    display: inline-block;
  }
  .headerStep ul li.active a h6 {
    color: #d14242;
  }
  .headerStep ul li.active a span {
    background: #d14242;
  }
  .headerStep ul li::before {
    content: '';
    width: 82px;
    height: 2px;
    background: #b3bac5;
    border-radius: 2.5px;
    display: inline-block;
    vertical-align: middle;
    margin: 0 10px;
    @media screen and (max-width: 1200px) {
      width: 40px;
    }
  }
  .headerStep ul li.active::before {
    background: #d14242;
  }
  .headerStep ul li:first-child::before {
    content: none;
  }
  .headerStep ul li a h6 {
    display: inline-block;
    margin: 0;
    margin-left: 10px;
    vertical-align: middle;
    color: #a5adba;
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
  }
  .headerStep ul li a span {
    font-weight: 500;
    width: 16px;
    height: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #fff;
    background: #a5adba;
    border-radius: 50px;
  }
  .RespHeader {
    position: relative;
    padding-left: 56px;
    margin-bottom: 32px;
    display: none;
    @media screen and (max-width: 1024px) {
      display: block;
    }
    &.Step5 {
      max-width: 800px;
      width: calc(100% - 40px);
      margin-left: auto;
      margin-right: auto;
      position: relative;
      z-index: 2;
      margin-bottom: 10px;
      padding-top: 20px;
      @media screen and (max-width: 600px) {
        max-width: 600px;
      }
    }
    .cirle {
      width: 42px;
      height: 42px;
      position: absolute;
      left: 0;
      .label {
        font-weight: bold;
        font-size: 12px;
        position: absolute;
        line-height: 38px;
        display: block;
        width: 100%;
        text-align: center;
        letter-spacing: 1.5px;
        border: 2px solid #a5adba;
        border-radius: 50%;
        b {
          color: #d14242;
        }
      }
      .circleProgress {
        position: relative;
        width: 42px;
        height: 42px;
        .circleCommon {
          width: 50%;
          height: 100%;
          overflow: hidden;
          position: absolute;
          top: 0;
          z-index: 1;
          .progressPercent {
            width: 100%;
            height: 100%;
            background: none;
            border-width: 2px;
            border-style: solid;
            position: absolute;
            top: 0;
            border-color: #d14242;
          }
          &.Left {
            left: 0;
            .progressPercent {
              left: 100%;
              border-top-right-radius: 80px;
              border-bottom-right-radius: 80px;
              border-left: 0;
              -webkit-transform-origin: center left;
              transform-origin: center left;
              transform: rotate(40deg);
            }
          }
          &.Right {
            right: 0;
            .progressPercent {
              left: -100%;
              border-top-left-radius: 80px;
              border-bottom-left-radius: 80px;
              border-right: 0;
              -webkit-transform-origin: center right;
              transform-origin: center right;
              animation: loading-1 1.8s linear forwards;
              transform: rotate(180deg);
            }
          }
        }
      }
    }
    h6 {
      color: #242c34;
      font-size: 16px;
      margin: 0;
    }
    span {
      color: #a5adba;
      font-size: 12px;
      display: block;
      margin-top: 0;
    }
  }
  .overlayButton {
    position: absolute;
    top: 10px;
    z-index: 2;
    left: 0;
    pointer-events: none;
    @keyframes ripple {
      0% {
        box-shadow: 0 0 0 0.2rem rgba(209, 66, 66, 0.2);
      }
      100% {
        box-shadow: 0 0 0 1.4rem rgba(209, 66, 66, 0);
      }
    }
    .ripple {
      position: absolute;
      background-color: #d14242;
      left: 8px;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      z-index: -1;
      animation: ripple 1s linear infinite;
    }

    .ripple::after {
      animation: ripple 1s linear infinite 1s;
    }
    .icon {
      font-size: 12px;
      min-width: 48px;
      min-height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: -8px;
    }
    svg {
      path {
        fill: white;
      }
    }
  }
`;

export const FixedFooter = styled.div`
  width: 100%;
  background: #ffffff;
  box-shadow: 0px -3px 30px rgba(163, 163, 164, 0.2);
  position: fixed;
  bottom: 0;
  padding: 0 20px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  /* .footerBodyWrp {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  } */
  .wrapper-footer {
    max-width: 1140px;
    width: 1140px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
  }
  .btnBack,
  .btnNext {
    box-sizing: border-box;
    border-radius: 8px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    width: 160px;
    cursor: pointer;
  }
  .btnBack {
    border: 2px solid #ebecf0;
    background: #fff;
    color: #2d3742;
  }
  .btnNext {
    background: #d14242;
    color: #fff;
  }
`;
