/* eslint-disable prettier/prettier */
export const appData = {
  appLMS: {
    info: {
      title1: 'LMS',
      title2: '(App quản lý việc học)',
      subtitle:
        'Quản lý tài liệu học tập. Làm bài online và nhận bài sửa cực chi tiết!'
    },
    onboarding: [
      {
        message: 'Truy cập kho tài liệu của khoá học mọi lúc mọi nơi.',
        left: '90%',
        top: '15%',
        width: '250px',
        position: 'bottom center',
        positionMobile: 'left center'
      },
      {
        message:
          'Dễ dàng theo dõi bài tập trong lớp. Bài làm của bạn được chấm chi tiết đến từng lỗi nhỏ và gợi ý cách viết hay hơn!',
        left: '10%',
        top: '80%',
        width: '250px',
        position: 'top center',
        positionMobile: 'top left'
      }
    ]
  },
  appOnlineTest: {
    info: {
      title1: 'Online Tests',
      title2: '(kho đề thật)',
      subtitle:
        'Làm bài với giao diện thi IELTS thật trên máy tính, kèm lời giải chi tiết cho từng đáp án.'
    },
    onboarding: [
      {
        message:
          'Kho đề đa dạng, có đề thi thật. Giao diện trên máy tính theo chuẩn thi IELTS tại BC và IDP.',
        left: '45%',
        top: '36%',
        width: '250px',
        position: 'right center',
        positionMobile: 'top center'
      },
      {
        message:
          'Gợi ý đề phù hợp với trình độ của bạn dựa trên phân tích các dạng câu hỏi và chủ đề mà bạn hay sai.',
        left: '5%',
        top: '80%',
        width: '250px',
        position: 'right center',
        positionMobile: 'right center'
      }
    ]
  },
  appVocab: {
    info: {
      title1: 'Vocabulary Builder',
      title2: '(tích hợp AI)',
      subtitle:
        'Dự đoán những từ có thể bạn chưa biết thông qua kết quả làm bài tại Online Tests'
    },
    onboarding: [
      {
        message:
          'Bộ từ vựng cho riêng bạn dựa vào những câu trả lời bạn làm sai ở Online Tests',
        left: '67%',
        top: '14%',
        width: '250px',
        position: 'bottom center',
        positionMobile: 'bottom center'
      },
      {
        message: 'Giúp bạn nhớ từ nhanh hơn thông qua game, quiz, flashcard...',
        left: '5%',
        top: '80%',
        width: '250px',
        position: 'top center',
        positionMobile: 'top left'
      }
    ]
  }
};
